import React from 'react';
import ReactTooltip from 'react-tooltip';
import { CONSTANTS } from './utils/constants';

const App = ({ element }) => {
	return (
		<React.Fragment>
			{element}
			<ReactTooltip effect="solid" type="dark" />
		</React.Fragment>
	);
};
export const wrapPageElement = ({ element }) => <App element={element} />;

const replaceLinks = () => {
	const tags = document.getElementsByTagName('a');
	const inital_ref = localStorage.getItem(CONSTANTS.INITIAL_REF_PARAM_KEY);
	const referrer = document.referrer ? new URL(document.referrer) : null;
	if (referrer) {
		if (!CONSTANTS.WEB_URLS.includes(referrer.host)) {
			setInitialReferrerLocalStorage(referrer.href);
			setInitialReferrer(tags, referrer.href);
		} else if (inital_ref) {
			setInitialReferrer(tags, inital_ref);
		}
	} else if (inital_ref) {
		setInitialReferrer(tags, inital_ref);
	}
};

const setInitialReferrerLocalStorage = ref_url => {
	localStorage.setItem(CONSTANTS.INITIAL_REF_PARAM_KEY, ref_url);
};

const setInitialReferrer = (tags, ref_url) => {
	for (let i = 0; i < tags.length; i++) {
		try {
			const url = new URL(tags[i].href);
			if (CONSTANTS.APP_URLS.includes(url.host) && url.pathname === '/signup') {
				if (!url.searchParams.has(CONSTANTS.INITIAL_REF_PARAM_KEY))
					url.searchParams.append(CONSTANTS.INITIAL_REF_PARAM_KEY, ref_url);
				tags[i].href = url.href;
			}
		} catch {
			// continue the flow if href for a tag is not found
		}
	}
};

// these query params will not me maintained while redirecting
const FILTER_QUERY_PARAMS = [
	'search',
	'filterby_industries',
	'filterby_stages',
];

export const onRouteUpdate = data => {
	replaceLinks();
	if (data.prevLocation) {
		const newUrl = new URL(data.location.href);
		const prevParams = new URLSearchParams(data.prevLocation.search);
		prevParams.forEach((value, key) => {
			if (!newUrl.searchParams.has(key) && !FILTER_QUERY_PARAMS.includes(key)) {
				newUrl.searchParams.append(key, value);
			}
		});
		const newPath = `${newUrl.pathname}${newUrl.search}`;
		window.history.replaceState({ path: newPath }, '	', newPath);
		data.location.search = newUrl.search;
	}
};
