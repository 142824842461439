// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-dynamic-pages-customer-support-js": () => import("./../../../src/dynamicPages/customer-support.js" /* webpackChunkName: "component---src-dynamic-pages-customer-support-js" */),
  "component---src-dynamic-pages-mid-market-js": () => import("./../../../src/dynamicPages/mid-market.js" /* webpackChunkName: "component---src-dynamic-pages-mid-market-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bot-builder-js": () => import("./../../../src/pages/bot-builder.js" /* webpackChunkName: "component---src-pages-bot-builder-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-enterprise-chatbot-js": () => import("./../../../src/pages/enterprise-chatbot.js" /* webpackChunkName: "component---src-pages-enterprise-chatbot-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-human-handover-js": () => import("./../../../src/pages/human-handover.js" /* webpackChunkName: "component---src-pages-human-handover-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-live-chat-tool-js": () => import("./../../../src/pages/live-chat-tool.js" /* webpackChunkName: "component---src-pages-live-chat-tool-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-property-management-chatbot-js": () => import("./../../../src/pages/property-management-chatbot.js" /* webpackChunkName: "component---src-pages-property-management-chatbot-js" */),
  "component---src-pages-real-estate-chatbot-js": () => import("./../../../src/pages/real-estate-chatbot.js" /* webpackChunkName: "component---src-pages-real-estate-chatbot-js" */),
  "component---src-pages-searchblog-js": () => import("./../../../src/pages/searchblog.js" /* webpackChunkName: "component---src-pages-searchblog-js" */),
  "component---src-pages-small-business-js": () => import("./../../../src/pages/small-business.js" /* webpackChunkName: "component---src-pages-small-business-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-templates-analytics-dashboard-template-js": () => import("./../../../src/templates/analytics-dashboard-template.js" /* webpackChunkName: "component---src-templates-analytics-dashboard-template-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/author-template.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-blog-category-template-js": () => import("./../../../src/templates/blog-category-template.js" /* webpackChunkName: "component---src-templates-blog-category-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-comparison-homepage-js": () => import("./../../../src/templates/comparison-homepage.js" /* webpackChunkName: "component---src-templates-comparison-homepage-js" */),
  "component---src-templates-comparison-page-template-js": () => import("./../../../src/templates/comparison-page-template.js" /* webpackChunkName: "component---src-templates-comparison-page-template-js" */),
  "component---src-templates-comparison-page-template-v-2-js": () => import("./../../../src/templates/comparison-page-template-v2.js" /* webpackChunkName: "component---src-templates-comparison-page-template-v-2-js" */),
  "component---src-templates-customer-page-template-js": () => import("./../../../src/templates/customer-page-template.js" /* webpackChunkName: "component---src-templates-customer-page-template-js" */),
  "component---src-templates-demand-generation-template-js": () => import("./../../../src/templates/demand-generation-template.js" /* webpackChunkName: "component---src-templates-demand-generation-template-js" */),
  "component---src-templates-faq-builder-template-js": () => import("./../../../src/templates/faq-builder-template.js" /* webpackChunkName: "component---src-templates-faq-builder-template-js" */),
  "component---src-templates-new-whatsap-chatbot-template-js": () => import("./../../../src/templates/new-whatsap-chatbot-template.js" /* webpackChunkName: "component---src-templates-new-whatsap-chatbot-template-js" */),
  "component---src-templates-new-white-label-template-js": () => import("./../../../src/templates/new-white-label-template.js" /* webpackChunkName: "component---src-templates-new-white-label-template-js" */),
  "component---src-templates-outbound-bots-template-js": () => import("./../../../src/templates/outbound-bots-template.js" /* webpackChunkName: "component---src-templates-outbound-bots-template-js" */),
  "component---src-templates-privacy-template-js": () => import("./../../../src/templates/privacy-template.js" /* webpackChunkName: "component---src-templates-privacy-template-js" */),
  "component---src-templates-real-estate-agent-template-js": () => import("./../../../src/templates/real-estate-agent-template.js" /* webpackChunkName: "component---src-templates-real-estate-agent-template-js" */),
  "component---src-templates-solution-page-template-js": () => import("./../../../src/templates/solution-page-template.js" /* webpackChunkName: "component---src-templates-solution-page-template-js" */),
  "component---src-templates-use-case-template-js": () => import("./../../../src/templates/use-case-template.js" /* webpackChunkName: "component---src-templates-use-case-template-js" */),
  "component---src-templates-whatsapp-chatbot-template-js": () => import("./../../../src/templates/whatsapp-chatbot-template.js" /* webpackChunkName: "component---src-templates-whatsapp-chatbot-template-js" */)
}

