import React from 'react';
import FaceBook from '../src/svg/facebook.svg';
import Linkedin from '../src/svg/linkedin.svg';
import Twitter from '../src/svg/twitter.svg';
import Reddit from '../src/svg/reddit.svg';
import Support from '../src/images/support.png';
import Sales from '../src/images/sales.png';
import Marketing from '../src/images/marketing.png';
import PopularBadge from '../src/svg/Popular-Badge.svg';
import Arrow from '../src/svg/pricing-page-arrow.svg';

export const CONSTANTS = {
	// blog blue CTA section content
	CTA_TITLE: 'Your customer is closer than you think!',
	CTA_SUB_TITLE: 'Experience WotNot FREE for 30 days',
	CTA_BUTTON_TEXT: 'Start your 30-day free trial',
	CTA_BUTTON_LINK: 'https://app.wotnot.io/preview/choose-template?url=',

	// page title
	INTEGRATION_PAGE_TITLE: 'Integrations',
	CUSTOMERS_PAGE_TITLE: 'Customer Stories with WotNot',
	COMMON_CHAT_BOT_POST_TEXT: '- WotNot | Chatbot development platform',
	COMMON_USE_CASE_POST_TITLE: '| Chatbot Use Cases',

	// Integration page
	ALL_COLLECTIONS: 'All Collections',

	SOCIAL_MEDIA_OBJECT: {
		Facebook: <FaceBook className={'facebook'} />,
		Linkedin: <Linkedin className={'linkedin'} />,
		Twitter: <Twitter className={'twitter'} />,
		Reddit: <Reddit className={'reddit'} />,
	},

	POPULAR_BADGE: <PopularBadge className={'popular-badge'} />,
	PRICING_PAGE_ARROW: <Arrow className={'pricing-page-arrow'} />,

	MOBILE_SCREEN_WIDTH: 768,

	// blog page social media

	APP_URLS: ['app.wotnot.io', 'app.test.wotnot.io', 'app.dev.wotnot.io'],
	WEB_URLS: ['wotnot.io', 'test.wotnot.io'],
	INITIAL_REF_PARAM_KEY: 'initial_ref',

	COMMON_BLOG_PRE_URL: 'https://wotnot.io/blog/',

	SOCIAL_SHARE_ARRAY: [
		{
			link: `http://www.facebook.com/sharer.php?u=${'page_url'}/&t=${'page_title'}`,
			type: 'Facebook',
		},
		{
			link: `http://linkedin.com/shareArticle?mini=true&title=${'page_title'}&url=${'page_url'}/`,
			type: 'Linkedin',
		},
		{
			link: `https://twitter.com/share?text=${'page_title'}&url=${'page_url'}/`,
			type: 'Twitter',
		},
		{
			link: `http://reddit.com/submit?url=${'page_url'}/&title=${'page_title'}`,
			type: 'Reddit',
		},
	],

	// cta

	BLACK_CTA_TITLE: 'Scale your business with chatbots today for free!',
	BLACK_CTA_SUBTITLE:
		'Build bots that help you generate leads and automate support, for absolutely no cost, free forever.',

	BLUE_CTA_BTN_TEXT: 'Chat Now',
	SIGN_UP_LINK: 'https://app.wotnot.io/signup',

	// image carousel

	IMAGE_CAROUSEL_LOGO: [Support, Sales, Marketing],
	READ_MORE: 'Read more',
	TABLE_HEADER_CELL: 'table-header-cell',
	TABLE_CELL: 'table-cell',
	ABOUT_AUTHOR_TEXT: 'About Author',
	POST_FROM_THIS_AUTHOR: 'Posts from this author',
	POSTS_FROM: 'Posts From: ',
	POPULAR_TEXT: 'POPULAR',

	// Hubspot

	HUBSPOT_FORM_SCRIPT_LINK: '//js.hsforms.net/forms/embed/v2.js',
	HUBSPOT_MEETINGS_SCRIPT_LINK:
		'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
};

export const DIMENSIONS = {
	HEADER_HEIGHT: '66px',
};

export const FORM_TYPES = {
	CONTACT: 'contact',
	DEMO: 'schedule_a_demo',
};

export const USECASE_TAGS_TYPE = {
	INDUSTRIES: 'industries',
	STAGES: 'stages',
};
